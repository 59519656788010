import {
  USER_MENU,
  USER_MENU_DISPLAY_NAMES,
} from 'components/Layouts/components/UserMenu';

import {
  CardOutlineIc,
  ChatboxOutlineIc,
  CreateOutlineIc,
  HeartOutlineIc,
  HelpCircleOutlineIc,
  PersonCircleOutlineIc,
  StarOutlineIc,
  TimeOutlineIc,
} from '@dsch/dd-icons';
import { CDN_STATIC_ASSETS } from 'utils';
import { theme } from 'helpers/Theme';
import type { MenuItem } from 'components/Toolkit/Menu/Menu';

const getMenuItems = (args: {
  selectedSection?: USER_MENU;
  historyChecks?: number;
  messages?: number;
  showMore?: boolean;
}): MenuItem[] => {
  const { selectedSection, historyChecks, messages, showMore } = args;
  return [
    {
      id: USER_MENU.MY_DONEDEAL,
      selected: selectedSection === USER_MENU.MY_DONEDEAL,
      displayName: USER_MENU_DISPLAY_NAMES.MY_DONEDEAL,
      link: `/dashboard/${USER_MENU.MY_DONEDEAL}`,
      icon: (
        <PersonCircleOutlineIc
          color={theme.colors.GREY_DARKER}
          width="20"
          height="20"
        />
      ),
      includeNextLink: true,
      itemHidden: false,
    },
    {
      id: USER_MENU.MY_ADS,
      selected: selectedSection === USER_MENU.MY_ADS,
      displayName: USER_MENU_DISPLAY_NAMES.MY_ADS,
      link: `/dashboard/${USER_MENU.MY_ADS}`,
      icon: (
        <CreateOutlineIc
          color={theme.colors.GREY_DARKER}
          width="20"
          height="20"
        />
      ),
      includeNextLink: true,
      itemHidden: false,
    },
    {
      id: USER_MENU.MESSAGES,
      selected: selectedSection === USER_MENU.MESSAGES,
      displayName: USER_MENU_DISPLAY_NAMES.MESSAGES,
      link: `/dashboard/${USER_MENU.MESSAGES}`,
      notifications: messages && messages > 0 ? messages : null,
      icon: (
        <ChatboxOutlineIc
          color={theme.colors.GREY_DARKER}
          width="20"
          height="20"
        />
      ),
      itemHidden: false,
      includeNextLink: true,
    },
    {
      id: USER_MENU.SAVED,
      selected: selectedSection === USER_MENU.SAVED,
      displayName: USER_MENU_DISPLAY_NAMES.SAVED,
      link: `/dashboard/${USER_MENU.SAVED}`,
      icon: (
        <HeartOutlineIc
          color={theme.colors.GREY_DARKER}
          width="20"
          height="20"
        />
      ),
      includeNextLink: true,
      itemHidden: false,
    },
    {
      id: USER_MENU.SEARCHES,
      selected: selectedSection === USER_MENU.SEARCHES,
      displayName: USER_MENU_DISPLAY_NAMES.SEARCHES,
      link: `/dashboard/${USER_MENU.SEARCHES}`,
      icon: (
        <StarOutlineIc
          color={theme.colors.GREY_DARKER}
          width="20"
          height="20"
        />
      ),
      includeNextLink: true,
      itemHidden: false,
    },
    {
      id: USER_MENU.BROWSING_HISTORY,
      selected: selectedSection === USER_MENU.BROWSING_HISTORY,
      displayName: USER_MENU_DISPLAY_NAMES.BROWSING_HISTORY,
      link: `/dashboard/${USER_MENU.BROWSING_HISTORY}`,
      icon: (
        <TimeOutlineIc
          color={theme.colors.GREY_DARKER}
          width="20"
          height="20"
        />
      ),
      includeNextLink: true,
      itemHidden: !showMore,
    },
    {
      id: USER_MENU.HISTORY_CHECKS,
      selected: selectedSection === USER_MENU.HISTORY_CHECKS,
      displayName: USER_MENU_DISPLAY_NAMES.HISTORY_CHECKS,
      link: `/dashboard/${USER_MENU.HISTORY_CHECKS}`,
      notifications: historyChecks && historyChecks > 0 ? historyChecks : null,
      icon: (
        <img
          src={`${CDN_STATIC_ASSETS}/images/illustrations/greenlight-dark.svg`}
          width="20"
          height="20"
          color={theme.colors.GREY_DARKER}
          alt=""
          role="presentation"
        />
      ),
      includeNextLink: true,
      itemHidden: !showMore,
    },
    {
      id: USER_MENU.PAYMENTS,
      selected: selectedSection === USER_MENU.PAYMENTS,
      displayName: USER_MENU_DISPLAY_NAMES.PAYMENTS,
      link: `/dashboard/${USER_MENU.PAYMENTS}`,
      icon: (
        <CardOutlineIc
          color={theme.colors.GREY_DARKER}
          width="20"
          height="20"
        />
      ),
      includeNextLink: true,
      itemHidden: !showMore,
    },
  ];
};

const getHelpItem = (showMore?: boolean) => {
  return {
    id: USER_MENU.HELP,
    displayName: 'Help',
    link: `https://hello.donedeal.ie/hc/en-us/`,
    newTab: true,
    icon: <HelpCircleOutlineIc color={theme.colors.GREY_DARKER} />,
    divider: true,
    itemHidden: showMore,
  };
};

export { getMenuItems, getHelpItem };
